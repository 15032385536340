<template>
  <div id="about" style="padding-top: 2px">
    <div class="textPage solidBg">
      <h2 class="fontLarge">About Us</h2>
      <div class="textContent">
        <div class="fontMedium"></div>
        <h3>Your Partner in Technology Solutions</h3>
        <p class="details">
          At Corbett Technologies, we specialize in delivering tailored software development,
          database design, systems integration, and web programming services to Australian
          government agencies and businesses of all sizes.
        </p>
        <p class="details">
          With extensive expertise across a broad spectrum of technologies, programming
          languages, databases, and operating systems, we are committed to providing
          cost-effective solutions that address your unique needs and challenges.
        </p>
        <p class="details">
          From front-end and server-side development to full-stack, desktop, and mobile
          solutions, our proven track record ensures your projects are completed on time,
          within budget, and to the highest standard.
        </p>
        <h3>Let’s bring your vision to life</h3>
        <p class="details">
          <a href="#contactUs">Contact us</a> to see how we can help you make your next
          project a success.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.fontLarge {
  text-align: center;
  padding-top: 0;
  line-height: 1.2;
}

.fontMedium {
  color: $menuColour;
}

@media screen {
  a:link {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  a:visited {
    color: inherit;
  }
}

@media print {
  a:link {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    text-decoration: none;
    color: inherit;
  }
}
</style>
