<template>
  <div class="sidemenu">
    <div class="closeDiv">
      <v-btn class="iconBtn" v-on:click.stop="$emit('close-drawer')" aria-label="Close side menu button">
        <v-icon icon="mdi-backburger" :color="darkTextColour" size="large" class="hamburger"></v-icon>
      </v-btn>
    </div>
    <div class="menuItem" v-for="(item, index) in items" :key="index">
      <a class="fontMedium" :href="item.href" @click="$emit('close-drawer')" :title="item.tooltip"
        :target="item.target">
        <h2>{{ item.title }}</h2>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          href: "#about",
          title: "About Us",
          tooltip: "Find out about Corbett Technologies and what we do.",
        },
        {
          href: "#ourCustomers",
          title: "Customers",
          tooltip: "A sample of our customers.",
        },
        {
          href: "https://how-far-home.web.app/about",
          title: "How-Far-Home",
          tooltip: "A Progressive Web App (PWA) for adventurous travelers, curious explorers, or simply someone who loves to know where you are in relation to your favorite spots.",
          target: "_blank",
        },
        {
          href: "https://listrello.netlify.app",
          title: "LisTrello",
          tooltip: "A utility for listing and printing Trello cards.",
          target: "_blank",
        },
        {
          href: "#contactUs",
          title: "Contact Us",
          tooltip:
            "Send us a message, we'll get back to you as soon as we can.",
        },
      ],
      darkTextColour: "#032a79",
    };
  },
};
</script>

<style lang="sass">
@import "@/scss/_variables.scss"

.sidemenu
  background-color: $menuColour
  height: 100%
  z-index: 14
  padding-top: 1rem
  padding-left: 2rem
  color: $lightColour
  opacity: 0.92
</style>

<style scoped lang="sass">
.closeDiv
  display: grid
  justify-content: end
  transform: translateX(-5%)

.iconBtn
  align-self: end !important
  z-index: 8

.hamburger
  transform: scale(2)
</style>

<style scoped>
.fontMedium {
  font-size: 1.5rem;
}

a:link {
  text-decoration: inherit;
  color: inherit;
}

a:visited {
  text-decoration: inherit;
  color: inherit;
}

.menuItem {
  margin-top: 2.5rem
}

.menuItem:hover {
  transform: scale(1.05);
  transition: transform 0.4s ease-in-out;
}

@media screen and (max-width: 300px) {
  .sidemenu {
    padding-left: 1rem
  }

  .fontMedium {
    font-size: 1.2rem;
  }

  .menuItem {
    margin-top: 2rem
  }
}
</style>
